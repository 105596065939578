import { createRouter, createWebHistory } from 'vue-router';

// Lazy loading components
const Homepage = () => import('../views/homepageView.vue');
const IspView = () => import('../views/ispView.vue');
const Uikitpage = () => import('../views/uikitpageView.vue');
const RuleEngine = () => import('../views/ruleEngineView.vue');
const Blog = () => import('../views/Blog/mainBlogPage.vue');
const BlogDetail = () => import('../views/Blog/blogDetailedPage.vue');

const routes = [
  {
    path: '/',
    name: 'homepage',
    component: Homepage,
    meta: {
      title: 'Homepage - Halleyx',
      description:
        'AI-Driven SaaS BSS Platform We empower telecom operators and MVNO/Es to finally “let go” of customizations.',
      canonical: 'https://www.halleyx.com/', // Add the canonical URL
    },
  },
  {
    path: '/decision-manager',
    name: 'decision-manager',
    component: RuleEngine,
    meta: {
      title: 'Decision Manager - Halleyx',
      description: 'Explore the decision manager features of Halleyx.',
      canonical: 'https://www.halleyx.com/decision-manager', // Add the canonical URL
    },
  },
  {
    path: '/isp',
    name: 'isp',
    component: IspView,
    meta: {
      title: 'ISP Solutions - Halleyx',
      description: 'Discover ISP solutions provided by Halleyx.',
      canonical: 'https://www.halleyx.com/isp', // Add the canonical URL
    },
  },
  {
    path: '/blog',
    name: 'blog',
    component: Blog,
    meta: {
      title: 'Blog - Halleyx',
      description: 'Read the latest articles and updates on our blog.',
      canonical: 'https://www.halleyx.com/blog', // Add the canonical URL
    },
  },
  {
    path: '/ui-kit',
    name: 'ui-kit',
    component: Uikitpage,
    meta: {
      title: 'UI Kit - Halleyx',
      description: 'UI Kit components for Halleyx.',
      canonical: 'https://www.halleyx.com/ui-kit', // Add the canonical URL
    },
  },
  {
    path: '/blog/:id',
    name: 'blog-detail',
    component: BlogDetail,
    meta: {
      title: 'Blog Detail - Halleyx',
      description: 'Read detailed articles on our blog.',
      canonical: (route) => `https://www.halleyx.com/blog/${route.params.id}`, // Dynamic canonical URL
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      return {
        selector: to.hash,
        behavior: 'smooth',
      };
    } else if (savedPosition) {
      return savedPosition;
    } else {
      return { top: 0 };
    }
  },
});

router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title;
  }
  if (to.meta.description) {
    document
      .querySelector('meta[name="description"]')
      .setAttribute('content', to.meta.description);
  }
  if (to.meta.canonical) {
    const canonicalLink = document.querySelector('link[rel="canonical"]');
    if (canonicalLink) {
      canonicalLink.setAttribute(
        'href',
        typeof to.meta.canonical === 'function'
          ? to.meta.canonical(to)
          : to.meta.canonical
      );
    } else {
      const link = document.createElement('link');
      link.setAttribute('rel', 'canonical');
      link.setAttribute(
        'href',
        typeof to.meta.canonical === 'function'
          ? to.meta.canonical(to)
          : to.meta.canonical
      );
      document.head.appendChild(link);
    }
  }
  next();
});

export default router;
